/**
 * CODE_COMMENTS_16
 */
import React from 'react'
import { Grid, Image } from 'semantic-ui-react'


import microstarKegCraftDualBrandingLogo from '../../../public/imagesAndIcons/logos/microstar-kegcraft-dual-branding-logo.png'
import microstarConstellationBrandingLogo from '../../../public/imagesAndIcons/logos/microstar-constellation-brand-logo.png'
import './styles/public-layout.css'


export default ({ children }) => (
  <div className="narrower-layout">
    {/* For alignment purposes, all displayed content within this grid needs */}
    {/* to be contained within a <Grid.Column> component; see */}
    {/* CODE_COMMENTS_97 for why. */}
    <Grid verticalAlign="middle" stackable columns={1}>
      <Grid.Column>
        <Image src={microstarKegCraftDualBrandingLogo || microstarConstellationBrandingLogo} fluid style={{ paddingTop: '25px', paddingBottom: '15px' }} />
        <main>{children}</main>
      </Grid.Column>
    </Grid>
  </div>
)
