import React, { useState } from 'react'
import orderBy_ from 'lodash/orderBy'

import capitalize_ from 'lodash/capitalize'
import HistoryTableCreator from '../../../HOC/HistoryTableCreator'
import ShipmentDetails from '../subFeatures/ShipmentDetails'

import {
  createDisplayedShipmentType,
  createDisplayedDestinationName,
  // createDisplayedPlannedShipDate,
  createDisplayedDeliveryDate,
  createDisplayedPlannedDeliveryDate,
  createOrEditDisplayedShipmentDate,
  createDisplayedAddress, createDisplayedMovementType,
} from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'
import {
  determineItemSkuIdsToIncludeAsFieldsInShipmentsDetail,
  determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
} from '../../../util/shipmentHistory/common/util'
import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'
import ShipAndRevertButtons from '../subFeatures/ShipAndRevertButtons'
import createAction from '../../../../../../redux/actions/createAction'
import { FETCH_UPDATE_SCHEDULED_SHIPMENT_SHIP_DATE } from '../../../../../../redux/actions/actionTypes'
import {
  filterHistoryObjectsWithUnrecognizedStatus, getDisplayedHumanReadableStatusOfHistoryItem,
  getShouldHistoryItemObjBeDisplayedInTable, isPurposeOfShipmentToFulfillKegOrder, ShipmentSearchField,
} from '../../../util/util'
import { scheduledShipmentsHistoryStatusesConfig } from '../../Form'
import { CUSTOMER_TYPES_WAREHOUSE } from '../../../../../../constants'

function createSkuQuantityQualityCellContent({
  entireItemSkusSlice,
  row,
  useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
}) {
  if (!row.lineItems) {
    return {}
  }
  const ackedLineItemObj = row.lineItems.filter(lineItem => lineItem.linkType === 'ACKED')
  let linkType
  if (
    useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged
    && isPurposeOfShipmentToFulfillKegOrder(row)
    && ackedLineItemObj.length > 0
  ) {
    linkType = 'ACKED'
  } else {
    linkType = 'SHIPPED'
  }
  const skus = []
  const qualityLevels = []
  const quantities = []
  row.lineItems.filter(lineItem => lineItem.linkType === linkType && lineItem.quantity > 0)
    .forEach(({ itemSkuId, quantity: quantityObj }) => {
          // eslint-disable-next-line max-len
      const { containerType, qualityLevel: qualityLevelObj, valueType } = entireItemSkusSlice?.[itemSkuId] || {}
      const skuObj = containerType ? `${containerType} - ${valueType}`:`${itemSkuId}`
      skus.push(skuObj)
      qualityLevels.push(capitalize_(qualityLevelObj))
      quantities.push(quantityObj)
    },
    )
  const customSortingOrder = ['L50', 'L30', 'L20', 'G9', 'HB', 'QB', 'SB', 'Pallet', 'WP']

  const skuQuantityQualityObjects = skus.map((sku, index) => ({
    sku,
    qualityLevel: qualityLevels[index],
    quantity: quantities[index],
  }))

  skuQuantityQualityObjects.sort((a, b) => {
    const findOrder = sku => {
      const match = customSortingOrder.find(order => sku.includes(order))
      return match? customSortingOrder.indexOf(match): Infinity
    }
    const indexA = findOrder(a.sku)
    const indexB = findOrder(b.sku)
    if (indexA === indexB) {
      return a.sku.localeCompare(b.sku)
    }
    return indexA - indexB
  })
  return {
    skus: skuQuantityQualityObjects.map(sqq => sqq.sku),
    quantities: skuQuantityQualityObjects.map(sqq => sqq.quantity),
    qualityLevels: skuQuantityQualityObjects.map(sqq => sqq.qualityLevel),
  }
}

function createQualityLevelFieldsDefinitions({
  entireItemSkusSlice,
  // itemSkuIdsToIncludeAsFieldsInTable,
  // For most types of shipments (e.g. outbound, keg fills), get the quantities
  // from the lineItems prop whose linkType is 'SHIPPED'. The only exception is
  // inbound empty keg shipments, in which case use items from the lineItems
  // prop whose linkType is 'ACKED' (if appropriate, otherwise, use 'SHIPPED').
  // This is necessary for inbound shipments arranged by MicroStar to fulfill a
  // keg order (in contrast to inbound shipments initiated by the web app user,
  // such as empty kegs from self-dist/pub) because if a user acknowledges an
  // inbound shipment claiming that a different number of kegs arrived than what
  // the distributor claimed were shipped, the web app should display the user's
  // acknowledged numbers in the table, not the shipped numbers.
  useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged=false,
}) {
  return {
    heading: 'Quality',
    cellContent: row => {
      const { qualityLevels } =createSkuQuantityQualityCellContent({
        entireItemSkusSlice,
        row,
        useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
      })
      return qualityLevels?.map(obj => <div style={{ width: '50px' }}>{obj}<br /></div>)
    },
  }
}

function createQuantityFieldsDefinitions({
  entireItemSkusSlice,
  // itemSkuIdsToIncludeAsFieldsInTable,
  // For most types of shipments (e.g. outbound, keg fills), get the quantities
  // from the lineItems prop whose linkType is 'SHIPPED'. The only exception is
  // inbound empty keg shipments, in which case use items from the lineItems
  // prop whose linkType is 'ACKED' (if appropriate, otherwise, use 'SHIPPED').
  // This is necessary for inbound shipments arranged by MicroStar to fulfill a
  // keg order (in contrast to inbound shipments initiated by the web app user,
  // such as empty kegs from self-dist/pub) because if a user acknowledges an
  // inbound shipment claiming that a different number of kegs arrived than what
  // the distributor claimed were shipped, the web app should display the user's
  // acknowledged numbers in the table, not the shipped numbers.
  useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged=false,
}) {
  return {
    heading: 'Quantity',
    cellContent: () => true,
    additionalCellContent: row => {
      const { quantities } =createSkuQuantityQualityCellContent({
        entireItemSkusSlice,
        row,
        useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
      })
      return quantities?.map(obj => <div style={{ width: '55px' }}>{obj}<br /></div>)
    },
  }
}

function createTotalQuantityFieldsDefinitions({
  entireItemSkusSlice,
  // itemSkuIdsToIncludeAsFieldsInTable,
  // For most types of shipments (e.g. outbound, keg fills), get the quantities
  // from the lineItems prop whose linkType is 'SHIPPED'. The only exception is
  // inbound empty keg shipments, in which case use items from the lineItems
  // prop whose linkType is 'ACKED' (if appropriate, otherwise, use 'SHIPPED').
  // This is necessary for inbound shipments arranged by MicroStar to fulfill a
  // keg order (in contrast to inbound shipments initiated by the web app user,
  // such as empty kegs from self-dist/pub) because if a user acknowledges an
  // inbound shipment claiming that a different number of kegs arrived than what
  // the distributor claimed were shipped, the web app should display the user's
  // acknowledged numbers in the table, not the shipped numbers.
  useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged=false,
}) {
  return {
    heading: 'Quantity',
    className: 'hidden',
    cellContent: row => {
      const { quantities } =createSkuQuantityQualityCellContent({
        entireItemSkusSlice,
        row,
        useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
      })
      return quantities?.reduce((acc, curr) => acc+curr, 0)
    },
    includeInTotalsRow: true,
  }
}

function createSkuFieldsDefinitions({
  entireItemSkusSlice,
  // itemSkuIdsToIncludeAsFieldsInTable,
  // For most types of shipments (e.g. outbound, keg fills), get the quantities
  // from the lineItems prop whose linkType is 'SHIPPED'. The only exception is
  // inbound empty keg shipments, in which case use items from the lineItems
  // prop whose linkType is 'ACKED' (if appropriate, otherwise, use 'SHIPPED').
  // This is necessary for inbound shipments arranged by MicroStar to fulfill a
  // keg order (in contrast to inbound shipments initiated by the web app user,
  // such as empty kegs from self-dist/pub) because if a user acknowledges an
  // inbound shipment claiming that a different number of kegs arrived than what
  // the distributor claimed were shipped, the web app should display the user's
  // acknowledged numbers in the table, not the shipped numbers.
  useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged=false,
}) {
  return {
    heading: 'SKU',
    cellContent: row => {
      const { skus } =createSkuQuantityQualityCellContent({
        entireItemSkusSlice,
        row,
        useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
      })
      return skus?.map(obj => <div style={{ width: '85px' }}>{obj}<br /></div>)
    },
  }
}

function createTableContentDefinitions({
  customerId,
  configuredItemSkuIds,
  allOutboundFullKegShipmentObjects,
  shouldOriginColumnBeRendered,
  operatingContractBrewerCustomerId,
  entireItemSkusSlice,
  onFormChangeValues,
  entireCustomersSlice,
  dispatch,
}) {
  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    shipments: allOutboundFullKegShipmentObjects,
  })

  return {
    content: [
      {
        heading: 'Shipment #',
        cellContent: row => (
          <ShipmentDetails
            dispatch={dispatch}
            entireCustomersSlice={entireCustomersSlice}
            entireItemSkusSlice={entireItemSkusSlice}
            customerId={customerId}
            info={row}
            shouldOriginColumnBeRendered={shouldOriginColumnBeRendered}
            itemSkuIdsToIncludeAsFieldsInTable={determineItemSkuIdsToIncludeAsFieldsInShipmentsDetail({
              entireItemSkusSlice,
              shipment: row,
            })}
          />
        ),
        customSortInfo: {
          sortFunction: (apiObjs, ascOrDesc) => (orderBy_(apiObjs, ['shipmentId'], [ascOrDesc])),
          sortApiObjectValuesRatherThanCellContents: true,
        },
      },
      (entireCustomersSlice?.[customerId]?.customerType === CUSTOMER_TYPES_WAREHOUSE ? {
        heading: 'Shipment Type',
        cellContent: createDisplayedShipmentType,
      }: {
        heading: 'Movement Type',
        cellContent: createDisplayedMovementType,
      }
      ),
      {
        heading: 'Destination',
        cellContent: createDisplayedDestinationName,
        customSortInfo: {
          columnNotSortable: true, // CODE_COMMENTS_248
        },
      },
      // {
      //   heading: 'Planned Ship Date',
      //   cellContent: createDisplayedPlannedShipDate,
      //   customSortInfo: {
      //     sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'plannedPickupDate' }),
      //     sortApiObjectValuesRatherThanCellContents: true,
      //   },
      // },
      {
        heading: 'Destination Address',
        cellContent: createDisplayedAddress,
        customSortInfo: {
          columnNotSortable: true, // CODE_COMMENTS_248
        },
      },
      {
        heading: 'Planned Delivery Date',
        cellContent: createDisplayedPlannedDeliveryDate,
        customSortInfo: {
          sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'plannedDeliveryDate' }),
          sortApiObjectValuesRatherThanCellContents: true,
        },
      },
      {
        heading: 'Delivery Date',
        cellContent: createDisplayedDeliveryDate,
        customSortInfo: {
          sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateReceived' }),
          sortApiObjectValuesRatherThanCellContents: true,
        },
      },
      {
        heading: 'Ship Date',
        cellContent: createOrEditDisplayedShipmentDate({
          onFormChangeValues,
        }),
        customSortInfo: {
          sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateShipped' }),
          sortApiObjectValuesRatherThanCellContents: true,
        },
      },
      {
        heading: 'Status',
        cellContent: shipmentObj => getDisplayedHumanReadableStatusOfHistoryItem(
          shipmentObj,
          scheduledShipmentsHistoryStatusesConfig,
          'status',
        ),
      },
      createSkuFieldsDefinitions({
        entireItemSkusSlice,
        itemSkuIdsToIncludeAsFieldsInTable,
      }),
      createQualityLevelFieldsDefinitions({
        entireItemSkusSlice,
        itemSkuIdsToIncludeAsFieldsInTable,
      }),
      createQuantityFieldsDefinitions({
        entireItemSkusSlice,
        itemSkuIdsToIncludeAsFieldsInTable,
      }),
      createTotalQuantityFieldsDefinitions({
        entireItemSkusSlice,
        itemSkuIdsToIncludeAsFieldsInTable,
      }),
      {
        heading: ' ',
        cellContent: row => (
          <ShipAndRevertButtons
            info={row}
            customerId={customerId}
            entireItemSkusSlice={entireItemSkusSlice}
            itemSkuIds={configuredItemSkuIds}
            operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
          />
        ),
      },
    ],
    defaultSortColumn: 'Ship Date',
    filterFunc: shipmentObj => (
      getShouldHistoryItemObjBeDisplayedInTable(
        shipmentObj,
        scheduledShipmentsHistoryStatusesConfig,
        'status',
      )
    ),
    rowKey: row => row.id,
  }
}

export default ({
  configuredItemSkuIds,
  allOutboundFullKegShipmentObjects,
  allNoMovementsObjects,
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  isFormSubmitting,
  hasFormSubmitFailed,
  noResultsMessage,
  shouldOriginColumnBeRendered,
  // areAnyItemsEditable,
  getIsindividualItemEditable,
  entireItemSkusSlice,
  dispatch,
  entireCustomersSlice,
}) => {
  const [shipmentSearch, setShipmentSearch] = useState('')
  const itemType = 'scheduled shipments'
  const allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable =
    filterHistoryObjectsWithUnrecognizedStatus(
      allOutboundFullKegShipmentObjects,
      scheduledShipmentsHistoryStatusesConfig,
      'status',
      shipmentSearch,
    )
  // const [formValues, onFormChangeValues] = useReducer(
  //   (formValues_, action) => ({
  //     ...formValues_,
  //     ...action,
  //   }), {})

  const onFormChangeValues = data => dispatch(createAction(FETCH_UPDATE_SCHEDULED_SHIPMENT_SHIP_DATE, {
    customerId,
    operatingContractBrewerCustomerId,
    rowData: { ...data },
  }))
  const definitions = createTableContentDefinitions({
    customerId,
    configuredItemSkuIds,
    allOutboundFullKegShipmentObjects,
    shouldOriginColumnBeRendered,
    operatingContractBrewerCustomerId,
    entireItemSkusSlice,
    onFormChangeValues,
    entireCustomersSlice,
    dispatch,
  })

  return (
    <React.Fragment>
      <ShipmentSearchField
        shipmentSearch={shipmentSearch}
        setShipmentSearch={setShipmentSearch}
        isFormSubmitting={isFormSubmitting}
      />
      <HistoryTableCreator
        tableInfoObj={allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable}
        definitions={definitions}
        isFormSubmitting={isFormSubmitting}
        hasFormSubmitFailed={hasFormSubmitFailed}
        noResultsMessage={noResultsMessage}
        additionalPropsToPassToTableCells={{ customerId, operatingContractBrewerCustomerId }}

        // for Edit and Delete buttons column
        // areAnyItemsEditable={areAnyItemsEditable}
        itemType={itemType}
        customerId={customerId}
        operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
        getIsindividualItemEditable={getIsindividualItemEditable}

        // For NoMovements rows
        specialInfoObj={allNoMovementsObjects}
      />
    </React.Fragment>
  )
}
