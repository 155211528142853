import { call, put, select, takeEvery } from 'redux-saga/effects'
import moment from 'moment'

import get_ from 'lodash/get'
import flow_ from 'lodash/fp/flow'
import valuesFp_ from 'lodash/fp/values'
import flattenFp_ from 'lodash/fp/flatten'
import mapFp_ from 'lodash/fp/map'
import isFunction_ from 'lodash/isFunction'
import { privateFetch } from '../../fetch'
import {
  FETCH_CUSTOMER_HOLIDAY_DAYS,
  FETCH_KEG_INDIVIDUAL_ORDER,
  FETCH_ORDER_KEGS,
  FETCH_WAREHOUSE_PICKUP_LOCATIONS,
  SAVE_CUSTOMER_HOLIDAY_LIST,
  SAVE_SINGLE_KEG_ORDER_HISTORY_ITEM,
} from '../../../actions/actionTypes'
import createAction from '../../../actions/createAction'
import {
  generalDoFailure,
} from '../../util/fetchFailure'

import {
  CONTRACT_METADATA_PROP_NAME_CONTRACT_BREWER_CID,
  CONTRACT_METADATA_PROP_NAME_CONTRACT_TYPE,
  CONTRACT_METADATA_PROP_NAME_PPF_CONTRACT_TYPE,
  DEFAULT_OR_ORIGIN_CONTRACT_METADATA_OBJECT_PROP_NAME,
  DEST_CONTRACT_METADATA_BRW_2_BRW_ONLY_PROP_NAME,
  KEG_ORDER_STATUS_ACCEPTED,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  API_URL_PATH_KEG_ORDERS,
  API_URL_PATH_KEG_ORDERS_HOLIDAY_DAYS,
  API_URL_PATH_KEG_ORDERS_PICKUP_LOCATIONS,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_WAREHOUSE,
} from '../../../../constants'

import {
  getProp as getCurrentUserProp,
} from '../../../selectors/currentUser'
import {
  getProp as getCustomerProp,
} from '../../../selectors/customers'

import {
  extractFormValues,
  doesKegOrderObjectMeetCriteriaOfHistoryForm,
} from './util'

import {
  formatDateForApiCall,
  getIndividualKegOrderApiUrl,
} from '../../../../utils'

function* fetchPickUpLocations(action) {
  const { country, businessUnitId: businessUnit, customerId } = action.payload
  let response
  try {
    response = yield call(
      privateFetch,
      {
        path: API_URL_PATH_KEG_ORDERS_PICKUP_LOCATIONS,
        params: {
          businessUnit,
          iso3country: country,
          cid: customerId,
        },
      },
    )
  } catch (error) {
    if (get_(error, ['response', 'status']) === 404) {
      response = { data: [] }
    } else {
      yield call(doFetchFailure, { error, ...action.payload })
    }
    return
  }
  yield call(doFetchSuccess, { response, ...action.payload })
}
export function* fetchCustomerHolidayDays(action) {
  const { customerId } = action.payload
  const customerType = yield select(getCustomerProp, customerId, 'customerType')
  if ([CUSTOMER_TYPES_MASTER, CUSTOMER_TYPES_WAREHOUSE].includes(customerType)) {
    return
  }
  let response
  try {
    response = yield call(
      privateFetch,
      {
        method: 'POST',
        path: `${API_URL_PATH_KEG_ORDERS_HOLIDAY_DAYS}/${customerId}`,
        data: {
            [DEFAULT_OR_ORIGIN_CONTRACT_METADATA_OBJECT_PROP_NAME]: {
              [CONTRACT_METADATA_PROP_NAME_PPF_CONTRACT_TYPE]: (customerType === CUSTOMER_TYPES_CONTRACT_BREWER && CUSTOMER_TYPES_CONTRACT_BREWER) || 'BRW',
              // eslint-disable-next-line max-len
              [CONTRACT_METADATA_PROP_NAME_CONTRACT_BREWER_CID]: (customerType === CUSTOMER_TYPES_CONTRACT_BREWER && customerId) || null,
              [CONTRACT_METADATA_PROP_NAME_CONTRACT_TYPE]: 'PPF',
            },
            [DEST_CONTRACT_METADATA_BRW_2_BRW_ONLY_PROP_NAME]: {
              [CONTRACT_METADATA_PROP_NAME_PPF_CONTRACT_TYPE]: (customerType === CUSTOMER_TYPES_CONTRACT_BREWER && CUSTOMER_TYPES_CONTRACT_BREWER) || 'BRW',
              // eslint-disable-next-line max-len
              [CONTRACT_METADATA_PROP_NAME_CONTRACT_BREWER_CID]: (customerType === CUSTOMER_TYPES_CONTRACT_BREWER && customerId) || null,
              [CONTRACT_METADATA_PROP_NAME_CONTRACT_TYPE]: 'PPF',
          },
        },
      },
    )
  } catch (error) {
    if (get_(error, ['response', 'status']) === 404) {
      response = { data: [] }
    } else {
      yield call(doFetchFailure, { error, ...action.payload })
    }
    return
  }
  yield put(createAction(SAVE_CUSTOMER_HOLIDAY_LIST, { customerId, info: { kegOrderDays: response?.data || [] } }))
}
function* fetchIndividualKegOrder(action) {
  const { orderId, setIsSubmitting } = action?.payload || {}
  setIsSubmitting(true)
  let response
  const fetchConfig = {
    path: getIndividualKegOrderApiUrl(orderId),
  }
  try {
    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    yield call(doFailure, { error, ...action.payload })
    return
  }
  yield call(doSuccess, { response, ...action.payload })
}

function* fetchOrderKegs(action) {
  const {
    customerId,
    itemSkuIds,
    formValues,
    setIsSubmitting,
    entireCustomersSlice,
    setKegOrderValidationMessage,
  } = action.payload
  setIsSubmitting(true)

  const fetchConfig = yield call(createFetchConfig, customerId, itemSkuIds, formValues, entireCustomersSlice)
  let response
  try {
    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    setKegOrderValidationMessage(error)
    yield call(doFailure, { error, ...action.payload })
    return
  }
  yield call(doSuccess, { response, ...action.payload })
}

function doFetchSuccess({
  setDidFetchFail,
  setPickUpLocations,
  response,
  setErrorMessage,
}) {
  const { data } = response
  const customerAddresses = flow_(
    mapFp_(o => {
      const { itemSkuIds } = o
      let { customerAddresses: addresses } = o
      addresses = addresses.map(address => ({ ...address, id: o.id, name: o.name, itemSkuIds }))
      return addresses
    }),
    valuesFp_,
    flattenFp_,
  )(data)

  setDidFetchFail(false)
  setPickUpLocations(customerAddresses)
  setErrorMessage('')
}

function* doFetchFailure({
  error,
}) {
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
}


function* doSuccess({
  customerId,
  response,
  setIsSubmitting,
  setDidSubmitFail,
  setDidSubmitSucceed,
  forceSave,
  callBack,
}) {
  const orderObj = response.data
  if (yield call(doesKegOrderObjectMeetCriteriaOfHistoryForm, customerId, orderObj, forceSave)) {
    yield put(createAction(SAVE_SINGLE_KEG_ORDER_HISTORY_ITEM, { savePath: [customerId], info: orderObj }))
  }
  if (isFunction_(setIsSubmitting)) { setIsSubmitting(false) }
  if (isFunction_(setDidSubmitSucceed)) { setDidSubmitSucceed(true) }
  if (isFunction_(setDidSubmitFail)) { setDidSubmitFail(false) }
  if (isFunction_(callBack)) { callBack() }
}


function* doFailure({
  error,
  setIsSubmitting,
  setDidSubmitFail,
  setDidSubmitSucceed,
}) {
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
  if (isFunction_(setIsSubmitting)) { setIsSubmitting(false) }
  if (isFunction_(setDidSubmitSucceed)) { setDidSubmitSucceed(false) }
  if (isFunction_(setDidSubmitFail)) { setDidSubmitFail(true) }
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_ORDER_KEGS, fetchOrderKegs],
  [takeEvery, FETCH_WAREHOUSE_PICKUP_LOCATIONS, fetchPickUpLocations],
  [takeEvery, FETCH_KEG_INDIVIDUAL_ORDER, fetchIndividualKegOrder],
  [takeEvery, FETCH_CUSTOMER_HOLIDAY_DAYS, fetchCustomerHolidayDays],
]


// helper functions

function* createFetchConfig(customerId, itemSkuIds, formValues, entireCustomersSlice) {
  const apiRequestBody = yield call(createApiRequestBody, customerId, itemSkuIds, formValues, entireCustomersSlice)

  const customerType = yield select(getCustomerProp, customerId, 'customerType')
  // CODE_COMMENTS_124
  const params = customerType === CUSTOMER_TYPES_CONTRACT_BREWER
    ? { ppfcontracttype: 'CBMST' }
    : {}

  const fetchConfig = {
    path: API_URL_PATH_KEG_ORDERS,
    data: apiRequestBody,
    method: 'POST',
    params,
  }

  return fetchConfig
}


function* createApiRequestBody(customerId, itemSkuIds, formValues, entireCustomersSlice) {
  const userId = yield select(getCurrentUserProp, 'userId')
  const extractedFormValues = extractFormValues(itemSkuIds, formValues, customerId, entireCustomersSlice)


  return assembleApiRequestBodyObj({
    customerId,
    userId,
    ...extractedFormValues,
  })
}

function assembleApiRequestBodyObj({
  customerId,
  userId,
  dateNeeded,
  orderComments,
  custPonum,
  shipAddressId,
  orderLineItemObjects,
  orderType,
  pickupFacilityId,
  deliveryScheduleObjects,
}) {
  return {
    customerId,
    userId,
    dateNeeded,
    custPonum,
    orderComments,
    shipAddressId,
    orderLineItemObjects,
    deliveryScheduleObjects,
    orderType,
    pickupFacilityId,
    // defaults
    dateOrdered: formatDateForApiCall({ date: moment() }),
    id: null,
    status: KEG_ORDER_STATUS_ACCEPTED,
    entryMethod: 'WEB',
  }
}
